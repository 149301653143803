import React, {Component, useState} from 'react';
import './App.css';
import LampControl from "./LampControl";
import Lamp, {LampConfig} from "./Lamp";
import Canvas from "./Canvas";
import Draggable from 'react-draggable';

const resizeLamp = (lamp: LampConfig, delta: number) => {
    return {...lamp, size: lamp.size + delta};
}

const App = () => {
    const defaultLampConfig: LampConfig = {size: 25, blur: 10, color: { h: 0, s: 0, v: 100, a: 1}, brightness: 1}
    const [lamp1, setLamp1] = useState<LampConfig>({ ...defaultLampConfig });
    const [lamp2, setLamp2] = useState<LampConfig>({ ...defaultLampConfig });
    const [configEnabled, setConfigEnabled] = useState<boolean>(true);

    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key == ' ') {
            setConfigEnabled(!configEnabled);
        }

        if (event.key == 'q') {
            setLamp1(resizeLamp(lamp1, 10));
        }
        if (event.key == 'a') {
            setLamp1(resizeLamp(lamp1, -10));
        }
        if (event.key == 'w') {
            setLamp2(resizeLamp(lamp2, 10));
        }
        if (event.key == 's') {
            setLamp2(resizeLamp(lamp2, -10));
        }
    };

    return <div className="App"  onKeyDown={keyDownHandler} tabIndex={0}>
        <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <header className="App-header">
            <Canvas>
                <Draggable><Lamp config={lamp1}/></Draggable>
                <Draggable><Lamp config={lamp2}/></Draggable>
            </Canvas>
            { configEnabled ? <div className="settings">
                <LampControl name="Lamp 1" config={lamp1} onChange={setLamp1}/>
                <LampControl name="Lamp 2" config={lamp2} onChange={setLamp2}/>
            </div> : null }
        </header>
    </div>;

}
export default App;
