import {Component} from "react";
import CSS from 'csstype';
import {hsvaToHex, HsvaColor} from "@uiw/react-color";

export type LampConfig = {
    size: number;
    blur: number;
    color: HsvaColor;
    brightness: number;
}

const Lamp = (props: { config: LampConfig }) => {
    const {config, ...rest} = props;
    const pxSize = props.config.size*10;
    const circleStyle: CSS.Properties = {
        backgroundColor: hsvaToHex(props.config.color),
        opacity: props.config.brightness,
        width: pxSize + "px",
        height: pxSize + "px",
        borderRadius: "50%",
        display: "inline-block",
        filter: 'blur(' + props.config.blur +'px)',
        left: -pxSize/2 + "px",
        top: -pxSize/2 + "px",
        position: 'absolute'
    };

    return <div {...rest} className="lampcontainer"><div className="lamp" style={circleStyle}/></div>
}

export default Lamp;