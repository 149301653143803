import React, {Component} from 'react';
import Slider from '@mui/material/Slider';
import Lamp, {LampConfig} from "./Lamp";
import {Box, InputLabel, Typography} from "@mui/material";
import {ColorResult, hexToHsva, HsvColor, Wheel} from '@uiw/react-color';

type LampConfigProps = {
    name: string,
    config: LampConfig,
    onChange: (state: LampConfig) => void
}

const LampControl = (props: LampConfigProps) => {
    const [config, setConfig] = React.useState<LampConfig>(props.config);
    const handleSizeChange = (event: Event, value: number | number[], activeThumb: number) => {
        config.size = value as number;
        props.onChange({...config});
    }

    const handleBlurChange = (event: Event, value: number | number[], activeThumb: number) => {
        config.blur = value as number;
        props.onChange({...config});
    }

    const handleColorChange = (color: ColorResult) => {
        config.color = color.hsva;
        props.onChange({...config});
    }

    const handleAlphaChange = (event: Event, brightness: number | number []) => {
        config.brightness = brightness as number / 100;
        props.onChange( {...config});
    }

    return <Box className="lampconfig">
        <Typography variant="h6">{props.name}</Typography>
        <Box sx={{ m: 3 }}>
        <Typography gutterBottom>Spot size</Typography>
        <Slider value={config.size} onChange={handleSizeChange}/>
        <Typography gutterBottom>Softness</Typography>
        <Slider value={config.blur} onChange={handleBlurChange}/>
        <Typography gutterBottom>Brightness</Typography>
        <Slider value={config.brightness * 100} onChange={handleAlphaChange}/>
        <Typography gutterBottom>Color</Typography>
        <Wheel className="colorPicker" color={{h: config.color.h, s: config.color.s, v: 100, a: 1}} onChange={handleColorChange}/>
        </Box>
    </Box>;
};

export default LampControl;
